import React, { useState } from 'react';
import kajiWebsite from './kaji.jpg';
import auvBottle from './auvelity-site.jpg';
import osapSite from './osap.jpg';
import petalsSite from './petals.jpg';
import profileImage from './profile-image.jpg';
import GradientCard from './components/GradientCard';
import Modal from './components/Modal';
import FitForm from './components/FitForm';
// src/index.js or src/App.js
import './fonts/fonts.css';

import {
  GlobalStyle,
  Container,
  Navbar,
  Section,
  Footer,
  ResetButton,
  ExitLink
} from './styles.js';

function App() {
  const IntroCard = () => {
    return (
      <>
        <h2>Hi, I'm Vince</h2>
        <p>software developer, freelancer, brand owner</p>
        <div className="social-icons">
          <a href="https://www.linkedin.com/in/vincentnafrada/" className="contact-button">contact me <i className="fab fa-linkedin"></i></a>
          {/* <a className="contact-button" onClick={openModal}>curious if we're aligned?</a> */}

        </div>
      </>
    )
  };

  const ContactCard = () => (
    <>
      <h2>Feel free to reach out for opportunities or just a friendly hello</h2>
      <p>you can find my email and resume on linkedin</p>
      <div className="social-icons">
        <a href="https://www.linkedin.com/in/vincentnafrada/" className="contact-button"><i className="fab fa-linkedin"></i></a>
      </div>
    </>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <Container>
      <GlobalStyle />
      <Navbar id="top">
        <a href="#work">Work</a>
        <a href="#contact">Contact</a>

      </Navbar>
      <Section>
        <div className="card-row">
          <GradientCard openModal={openModal} > <IntroCard /></GradientCard>
          <div className="card">
            <img className="card-photo" src={profileImage} alt="Profile" />
          </div>
        </div>
      </Section>
      <main>
        <Section id="work">
          <div className="card-row">
            <div className="card">
              <a href="https://www.auvelity.com" target="_blank" rel="noopener noreferrer">
                <img className="card-photo" src={auvBottle} alt="AuvBottle" />
                <div className="card-text">
                  <div>
                    <p className="bold">Klick Health</p>
                    <p>2020 - Present</p>
                  </div>
                  <div>
                    <p>Development (Web)</p>
                    <p>Development (Back-End)</p>
                    <p>Internal Tooling Development</p>
                  </div>
                </div>
                <ExitLink>
                  <i className="fas fa-arrow-up-right-from-square"></i>
                </ExitLink>
              </a>
            </div>
            <div className="card">
              <a href="https://www.kajikitchen.com" target="_blank" rel="noopener noreferrer">
                <img className="card-photo" src={kajiWebsite} alt="KajiKitchen" />
                <div className="card-text">
                  <div>
                    <p className="bold">Kaji Kitchen</p>
                    <p>2023 - Present</p>
                  </div>
                  <div>
                    <p>Shopify Development (Full Stack)</p>
                    <p>Ecommerce Website</p>
                    <p>UI Design</p>
                  </div>
                </div>
                <ExitLink>
                  <i className="fas fa-arrow-up-right-from-square"></i>
                </ExitLink>
              </a>
            </div>
          </div>
          <div className="card-row">
            <div className="card">
              <img className="card-photo" src={petalsSite} alt="Petals" />
              <div className="card-text">
                <div>
                  <p className="bold">Freelance Shopify Dev</p>
                  <p>2023 - Present</p>
                </div>
                <div>
                  <p>Shopify Development (Full Stack)</p>
                  <p>GTM Implementaion</p>
                  <p>UI Design Consulting</p>
                  <p>App Integrations</p>
                </div>
              </div>
            </div>
            <div className="card">
              <a href="https://www.ontario.ca/page/osap-ontario-student-assistance-program" target="_blank" rel="noopener noreferrer">
                <img className="card-photo" src={osapSite} alt="OSAP" />
                <div className="card-text">
                  <div>
                    <p className="bold">Ministry of Education</p>
                    <p>2017 - 2018</p>
                  </div>
                  <div>
                    <p>Development (Web)</p>
                    <p>Test Driven Development</p>
                  </div>
                </div>
                <ExitLink>
                  <i className="fas fa-arrow-up-right-from-square"></i>
                </ExitLink>
              </a>
            </div>
          </div>
        </Section>
        <Section id="contact">
          <div className="card-content">
            <GradientCard fullWidth={true} ><ContactCard /></GradientCard>
          </div>
        </Section>

        <div className="resetTop">
          <ResetButton href="#top">
            <i className="fas fa-arrow-up"></i>
          </ResetButton>
        </div>
      </main>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <FitForm onClose={closeModal} />
      </Modal>
    </Container>
  );
}

export default App;
